$(document).ready(function() {
  // function resizeMasonry() {
    $('.grid_block').masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-item'
    });
  // }
  // $(window).on('resize', function() {
  //   resizeMasonry();
  // });
});
