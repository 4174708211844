/*HOW TO CHANGE PLAY ICON IN YOUTUBE IFRAME*/
jQuery(document).ready(function() {
  jQuery('.img-video').each(function(i, o){
    if(jQuery(o).attr('data-src')) {
      jQuery(o).attr(
        'src',
        'https://img.youtube.com/vi/' +
        jQuery(o).attr('data-src')
        .replace('<iframe width="640" height="360" src="https://www.youtube.com/embed/', '')
        .replace('?feature=oembed" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>', '')
        + '/hqdefault.jpg'
      )
    }
  })
  jQuery('.img-small-video').each(function(i, o){
    if(jQuery(o).attr('data-src')) {
      jQuery(o).attr(
        'src',
        'https://img.youtube.com/vi/' +
        jQuery(o).attr('data-src')
        .replace('<iframe width="640" height="360" src="https://www.youtube.com/embed/', '')
        .replace('?feature=oembed" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>', '')
        + '/hqdefault.jpg'
      )
    }
  })
  jQuery('.icon-play-ytb').on('click', function() {
    var iframe_obj = jQuery(this).closest('.video-wrap').find('iframe');
    var url = iframe_obj.attr('src');
    var newUrl = url.indexOf('?')>-1?url + "&autoplay=1": url + "?autoplay=1";
    setTimeout(function(){
      jQuery('.icon-play-ytb').css('display', 'none');
      jQuery('.img-video').css('display', 'none');
    },1000);
    iframe_obj.attr('src', newUrl);
  });
});



// video pop up lightbox

jQuery(document).ready(function($) {

  /* This is basic - uses default settings */
  $("a#single_image").fancybox();

  /* Using custom settings */
  $("a#inline").fancybox({
    'hideOnContentClick': true
  });

  /* Apply fancybox to multiple items */
  $("a.group").fancybox({
    'transitionIn'	:	'elastic',
    'transitionOut'	:	'elastic',
    'speedIn'		:	600,
    'speedOut'		:	200,
    'overlayShow'	:	false,
    'maxWidth': '90%'
  });

});
