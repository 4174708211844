$(document).ready(function() {
  var currLang = document.documentElement.lang;

  if (currLang != 'en-US') {
    $('.mc4wp-form-fields .footer__input').attr('placeholder', 'Adresse courriel');
    $('.mc4wp-form-fields .button').attr('value', 'S\'inscrire');
  } else {
    $('.mc4wp-form-fields .footer__input').attr('placeholder', 'Your email address');
    $('.mc4wp-form-fields .button').attr('value', 'Sign up');
  }

});
